<!--
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-27 12:12:16
 * @LastEditTime: 2021-02-07 10:54:00
 * @FilePath: \acuconference-web\src\views\Download.vue
-->
<template>
  <div style="position: relative; width: 100%; height: 100%">
    <v-card>
      <v-app-bar class="elevation-0 v-bar--underline">
        {{ $t("label_Download_Center") }}
      </v-app-bar>
      <v-card-title v-hideInElectron="true">
        {{ $t("label_download_subtitle") }}
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6">
              <v-row>
                <v-col
                  cols="12"
                  style="
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <v-row>
                    <v-icon size="150"> mdi-microsoft-windows </v-icon>
                  </v-row>
                  <v-row style="margin-top: 16px">
                    <v-subheader>
                      {{ $t("label_download_win_client") }}
                    </v-subheader>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <v-btn color="primary" @click="openLink(downloadWin64Client)">
                    {{ $t("label_Download") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6">
              <v-row>
                <v-col
                  cols="12"
                  style="
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <v-row>
                    <v-icon size="150"> mdi-apple </v-icon>
                  </v-row>
                  <v-row style="margin-top: 16px">
                    <v-subheader>
                      {{ $t("label_download_macos_client") }}
                    </v-subheader>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <v-btn color="primary" @click="openLink(downloadMacOSClient)">
                    {{ $t("label_Download") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-divider style="margin: 30px 0" v-hideInElectron="true"></v-divider>
          <v-subheader class="title">
            {{ $t("label_title_other_tools") }}
          </v-subheader>
          <v-row>
            <v-col cols="12">
              <v-btn
                text
                color="primary"
                @click="openLink(macAudioRouterInstallerLink)"
              >
                {{ $t("label_btn_mac_audio_device") }}
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-btn
                text
                color="primary"
                @click="openLink(macAudioRouterUninstallerLink)"
              >
                {{ $t("label_btn_mac_audio_device_uninstaller") }}
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-btn
                text
                color="primary"
                @click="openLink(windowsAudioRouterInstallerLink)"
              >
                {{ $t("label_btn_win_audio_device") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  computed: {
    macAudioRouterInstallerLink() {
      return (
        process.env.VUE_APP_API_RES_HOST +
        process.env.VUE_APP_API_AUDIO_DEVICE_MAC_INSTALLER_LINK
      );
    },
    macAudioRouterUninstallerLink() {
      return (
        process.env.VUE_APP_API_RES_HOST +
        process.env.VUE_APP_API_AUDIO_DEVICE_MAC_UNSINTALLER_LINK
      );
    },
    windowsAudioRouterInstallerLink() {
      return (
        process.env.VUE_APP_API_RES_HOST +
        process.env.VUE_APP_API_AUDIO_DEVICE_WINDOWS_INSTALLER_LINK
      );
    },
    downloadMacOSClient() {
      return (
        process.env.VUE_APP_API_RES_HOST +
        process.env.VUE_APP_API_DOWNLOAD_CLIENT_MAC
      );
    },
    downloadWin64Client() {
      return (
        process.env.VUE_APP_API_RES_HOST +
        process.env.VUE_APP_API_DOWNLOAD_CLIENT_WIN_64
      );
    },
  },
  methods: {
    openLink(link) {
      window.open(link);
    },
  },
};
</script>

<style lang="sass" scoped>
</style>